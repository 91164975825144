import React, { useState }  from 'react';
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../../component/common/Helmet";
import HeaderThree from "../../component/header/HeaderThree";
import FooterTwo from "../../component/footer/FooterTwo";
import "react-modal-video/scss/modal-video.scss";
import ContactButton from '../../dark/contactbutton2';
import '../Blogs/blog.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
const blogPosts = [
  {
    id: 9,
    title: "Little Texas",
    date: "Nov 9, 2024",
    image: "https://i.imgur.com/ZyIZsV9.jpeg",
    previewText: "Last weekend at Little Texas in Belleville was one for the books! A huge thanks to the owners and staff...",
    content:
      <div>
        <p>Hey River Cats fans,</p>
        <p>Last weekend at Little Texas in Belleville was one for the books! A huge thanks to the owners and staff for the fantastic stage! it made for an incredible show. And thank you to everyone who came out to support us. Your energy was electric, and we can’t wait to be back!</p>
        <p>Stay tuned for more shows on the horizon. Until then, keep rockin!</p>
        <p>River Cats</p>
      </div>


    
  },
  {
    id: 8,
    title: "O.B.M.C. Rally",
    date: "Aug 24, 2024",
    image: "https://i.imgur.com/7RV2ISF.png",
    previewText: "This past weekend at the Old Bastards Vintage Motorcycle Rally at Lower Beverly Lake was unforgettable!...",
    content:
    <div>
      <p>Hey River Cats fans,</p>
      <p>This past weekend at the Old Bastards Vintage Motorcycle Rally at Lower Beverly Lake was absolutely unforgettable! A massive thank you to everyone who came out to make this event so special.</p>
      <p>The energy from the crowd was electric, and the dancing never stopped! The stunning lakeside view, combined with the roaring motorcycles and incredible music, set the stage for a weekend we’ll never forget.</p>
      <p>We were blown away by how engaged and lively everyone was—your enthusiasm was contagious! We’re so grateful to be part of this amazing community, and it was an honor to play for such an incredible group of people.</p>
      <p>Thanks again for making this rally one to remember. We can’t wait to see you at our next show!</p>
      <p>Ride on and rock on!</p>
      <p>River Cats</p>
    </div>
    
  },
  {
    id: 7,
    title: "Cultural Exchange",
    date: "Aug 24, 2024",
    image: "https://i.imgur.com/p5FG98o.jpeg",
    previewText: "This past Saturday at the 27th Cultural Exchange Raid Train was nothing short of amazing!...",
    content:
    <div>
    <p>Hey River Cats fans,</p>
    <p>This past Saturday at the 27th Cultural Exchange Raid Train was nothing short of amazing! A massive thank you to everyone who joined us for this extraordinary event.</p>
    <p>The Cultural Exchange, created by SaxDragon, is a one-of-a-kind initiative that unites music communities from various genres and regions. It’s all about collaboration and bringing together artists from around the world for a weekend filled with unforgettable music.</p>
    <p>We were absolutely thrilled to be part of this global celebration and to share our music with such a diverse and passionate audience. It was incredible to connect with fellow artists and fans from across the globe.</p>
    <p>If you missed the live show, make sure to follow us on Twitch <a href="https://www.twitch.tv/river_cats">HERE</a> for updates and future performances.</p>
    <p>Thanks again for being a part of this special event. We can’t wait to rock out with you at our next show!</p>
    <p>Rock on!</p>
    <p>River Cats</p>
    </div>
    
  },
  {
    id: 6,
    title: "Kingston Basin",
    date: "Aug 10, 2024",
    image: "../../../static/media/Cats%20Rocking%20@%20Confederation%20Basin.f1a6d9f1.jpg",
    previewText: "What an incredible time we had performing at Confederation Basin in downtown Kingston!...",
    content:
    <div>
      <p>Hey River Cats fans,</p>
      <p>What an incredible time we had performing at Confederation Basin in downtown Kingston! The atmosphere was electric, and playing in such a vibrant part of the city was an absolute thrill.</p>
      <p>A massive thank you to everyone who came out to support us. Your cheers, singing and dancing brought an incredible energy that made the entire afternoon truly unforgettable. We felt the love from the stage, and it meant the world to us.</p>
      <p>We also want to give a huge shoutout to the amazing soundman who ran the PA and monitors, making sure we sounded our best. And of course, a big thank you to the city of Kingston for having us and making it all possible.</p>
      <p>We appreciate each and every one of you for making this show something special. We can't wait to rock with you again soon!</p>
      <p>Rock on!</p>
      <p>River Cats</p>
    </div>
  },
  {
    id: 5,
    title: "Rays Cottage",
    date: "Aug 3, 2024",
    image: "../../../static/media/Rockin%20out%20@%20Rays%20Cottages.9424c0fc.jpg",
    previewText: "What a fantastic evening we had performing at Ray's Cottages! Playing outside on the picturesque...",
    content:
    <div>
      <p>Hey River Cats fans,</p>
      <p>What a fantastic evening we had performing at Ray's Cottages! Playing outside on the picturesque waterfront was an experience we won't soon forget.</p>
      <p>The serene backdrop of the water, combined with the beautiful weather, made for an unforgettable show. A big thank you to everyone who came out to support us and enjoy the music. Your energy and enthusiasm made the night truly special.</p>
      <p>We'd also like to extend our gratitude to the wonderful team at Ray's Cottages for their hospitality and for creating such a perfect setting for our performance.</p>
      <p>Thank you all for making it an evening to remember. We look forward to seeing you at our next show!</p>
      <p>Rock on!</p>
      <p>River Cats</p>
    </div>
  },
  {
    id: 4,
    title: "Royal Tavern 2.0",
    date: "July 26, 2024",
    image: "../../../static/media/Band%20@Royal%20Tavern%202.0%20(Photo-James%20C%20McColl).60ad5d63.jpg",
    previewText: "What an incredible night we had at the Royal Tavern 2.0 for Girls Night Out! We want to extend a huge thank you to...",
    content: <div>
    <p>Hey River Cats fans,</p>
    <p>What an incredible night we had at the Royal Tavern 2.0 for Girls Night Out! We want to extend a huge thank you to everyone who came out to join us. We had a blast, but we have to admit, it looked like the ladies were having an even better time on the dance floor!</p>
    <p>Special thanks to Kimberley and Jamie for being such amazing hosts. Your support for local music is truly appreciated, and we’re grateful for all you do to make these events special.</p>
    <p>A big shout out also goes to Jeff Richardson, who rocked the stage before us—your performance was top-notch!</p>
    <p>Thank you all for making the night so memorable. We can’t wait to do it again soon!</p>
    <p>Rock on!</p>
    <p>River Cats</p>
  </div>
  
  },
  {
    id: 3,
    title: "Music Series",
    date: "July 12, 2024",
    image: "../../../static/media/Rockin%20@Music%20Series%20B-W@%20Gan.36ab7d12.jpg",
    previewText: "What an amazing night we had at the Joel Stone Park Music Series in Gananoque, Ontario! We played ...",
    content: <div>
    <p>Hey River Cats fans,</p>
    <p>What an amazing night we had at the Joel Stone Park Music Series in Gananoque, Ontario! We played “Into the Night” against the backdrop of the beautiful waterfront, and it was an evening to remember.</p>
    <p>A huge thank you to the organizers and sound crew for their incredible work. Your dedication and professionalism truly made the event shine. And of course, a big shoutout to our fans who kept the energy high and danced the night away! Your enthusiasm and support were the heart of the night.</p>
    <p>We loved every moment of performing in such a picturesque setting and connecting with all of you. Thanks for making the evening so special.</p>
    <p>A big shout out also goes to Colin Slack, who played on the stage before us ,you had a awesome set!</p>
    <p>Stay tuned for more updates and upcoming shows. We can’t wait to see you again!</p>
    <p>Rock on!</p>
    <p>River Cats</p>
  </div>
  
  },
  {
    id: 2,
    title: "Cultural Exchange",
    date: "May 4, 2024",
    image: "https://i.imgur.com/r33LXbY.png",
    previewText: "What a phenomenal experience we had on Saturday at the 26th Cultural Exchange Raid Train!...",
    content: <div>
    <p>Hey River Cats fans,</p>
    <p>What a phenomenal experience we had on Saturday at the 26th Cultural Exchange Raid Train! A huge thank you to everyone who joined us for this incredible event.</p>
    <p>The Cultural Exchange is a unique initiative started by SaxDragon to bridge the gap between music communities across different genres and regions. It’s all about collaboration and bringing together artists from around the globe for a weekend of unforgettable music.</p>
    <p>We were thrilled to be part of this global celebration and to share our music with such a diverse and enthusiastic audience. It was amazing to connect with fellow artists and fans from all over the world</p>
    <p>If you missed the live show, follow us on Twitch at <a href="https://www.twitch.tv/river_cats">HERE</a> for more updates and future performances.</p>
    <p>Thank you again for being part of this special event. We look forward to rocking out with you at our next show!</p>
    <p>Rock on!</p>
    <p>River Cats</p>
  </div>
  
  },
  {
    id: 1,
    title: "Blue Martini",
    date: "May 2, 2024",
    image: "../../../static/media/River%20Cats%20@Blu%20Martini.0e0f5bf0.jpg",
    previewText: "A Rockin' Thank You to Blu Martini! Hey River Cats fans, A huge shoutout and thank you to Blu Martini..",
    content: (
      <div>
        <p>Hey River Cats fans,</p>
        <p>What an incredible night we had on May 2nd, 2024, at the Royal Jam! A huge shoutout and thank you to Blu Martini for hosting such a memorable evening. We truly appreciate the warm welcome and fantastic vibe you created for us.</p>
        <p>To all our amazing fans who showed up and made the night special, a heartfelt thank you. Your energy and support mean the world to us. We loved every moment of playing for you and can't wait for the next chance to hit the stage together!</p>
        <p>Stay tuned for more updates and upcoming shows. We hope to see you again soon!</p>
        <p>Rock on!</p>
        <p>River Cats</p>
      </div>
    )
  },
];



const BlogPage = () => {
  const [open, setOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const handleOpen = (post) => {
    setSelectedPost(post);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPost(null);
  };

  return (
    <div className="active-dark">
      <Helmet pageTitle="Blog" />
      <HeaderThree id="home" homeLink="/" logo="symbol-dark" color="color-black" />
      
      <div id="blog" className="blog-area ptb--120 bg_color--1">
      <center><h1>Blog</h1></center>
        <div className="container">
          <section className="light">
            <div className="container py-2 blog-grid">
              {blogPosts.map((post) => (
                <div key={post.id} className="blog-card">
                  <div className="blog-card__img-container">
                    <img src={post.image} alt={post.title} className="blog-card__img" />
                  </div>
                  <div className="blog-card__content">
                    <h1 className="blog-card__title">{post.title}</h1>
                    <div className="blog-card__subtitle">{post.date}</div>
                    <div className="blog-card__bar"></div>
                    <div className="blog-card__preview-text">{post.previewText}</div>
                    <button className="btn11-hover color-1" onClick={() => handleOpen(post)}>Read More</button>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
      <ContactButton/>
      {selectedPost && (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box className="modal-content">
            <div className="modal-header">
              <img src={selectedPost.image} alt={selectedPost.title} className="modal-img" />
              <h1 id="modal-modal-title" className="modal-title">{selectedPost.title}</h1>
              <div className="modal-subtitle">{selectedPost.date}</div>
            </div>
            <div id="modal-modal-description" className="modal-body">
              {selectedPost.content}
            </div>
          </Box>
        </Modal>
      )}
      
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <FooterTwo />
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
    </div>
  );
};

export default BlogPage;