import React, { useState } from 'react';
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import "react-modal-video/scss/modal-video.scss";
import ContactButton from './contactbutton2';
import '../component/common/swiper.css';
import RandomImageComponent from './randomimg';

const imageArray2 = [
  'https://i.imgur.com/sc2bArz.png',
  'https://i.imgur.com/lwICfDE.png',
  'https://i.imgur.com/MXYJg2M.png',
  'https://i.imgur.com/zHU3sVl.png',
  'https://i.imgur.com/W687FqV.png',
  'https://i.imgur.com/qdGJhl1.jpeg',
  'https://i.imgur.com/oy8moOK.jpeg',
  'https://i.imgur.com/9qtCP2c.jpeg',
  'https://i.imgur.com/ksFx9pp.jpeg',
  'https://i.imgur.com/xmbL8zi.jpeg',
  'https://i.imgur.com/wXN3Pc4.jpeg',
  'https://i.imgur.com/5p7FbcC.jpeg',
  'https://i.imgur.com/yvJFN00.jpeg',
  'https://i.imgur.com/giH2DBu.jpeg',
  'https://i.imgur.com/bEkjU8U.jpeg',
];

const Support = () => {
  // eslint-disable-next-line
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="active-dark">
      <Helmet pageTitle="River Cats" />
      <HeaderThree id="home" homeLink="/" logo="symbol-dark" color="color-black" />
      <section></section>
      <div id="portfolio">
        <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner">
            <br />
            <br />
            <section className="light">
              <div className="container py-2">
                <div>
                 
     <a href="https://ko-fi.com/rivercats" rel="noreferrer" target="_blank">  <center><img className="donateslider" src="https://i.imgur.com/QDv8Wpz.jpeg" alt="Support Us"></img></center></a>
                </div><br/>
                <br />
                <br />
                <br />
                <article className="postcard light yellow">
                  <div className="postcard__img_link">
                    <RandomImageComponent imageArray={imageArray2} className="postcard__img" />
                  </div>
                  <div className="postcard__text t-dark">
                    <h1 className="postcard__title yellow">Support The Cats!</h1>
                    <div className="postcard__subtitle small"></div>
                    <div className="postcard__bar"></div>
                    <div className="postcard__preview-txt"><p>We are River Cats, a passionate group of musicians dedicated to bringing the energy and spirit of live music to our <br /> fans. While we don’t need support to keep going, any help you offer would mean the world to us and take our performances to the next level. </p>

<p>Visit our <a href="https://river-cats-shop.fourthwall.com/" target="_blank" rel="noopener noreferrer">shop</a> to grab some awesome gear and support us directly. Every tip counts!</p>

<p>Rock on,<br /> River Cats</p>

                    </div>
<ContactButton />
                    <br />
                  </div>
                </article>
              </div>
            </section>
          </div>
        </div>
      </div>
      <FooterTwo />
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
    </div>
  );
};

export default Support;
